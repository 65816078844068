import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
//import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
//import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
//import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { PlaylistAddCheck } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import UrlsListView from "./UrlsListView";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  image: {
    backgroundImage:
      "url(https://i.pinimg.com/originals/5b/86/09/5b860912c75d0d651124d5d5c43b0eae.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  paperList: {
    margin: theme.spacing(2, 2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  imagePreview: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    width: 60,
    height: 60
  },
  imagePreviewContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function Dashboard(props) {
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <div className={classes.paperList}>
          <UrlsListView
            data={props.data}
            handleToggle={props.handleToggle}
            onDeleteAction={props.onDeleteAction}
            onEditAction={props.onEditAction}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <AddNewUrlView {...props} />
      </Grid>
    </Grid>
  );
}

function AddNewUrlView(props) {
  const classes = useStyles();
  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <PlaylistAddCheck />
      </Avatar>
      <Typography component="h1" variant="h5">
        {props.editData ? "Update Information" : "Add New Live Url"}
      </Typography>
      <form className={classes.form} noValidate onSubmit={props.handleSubmit}>
        <div className={classes.imagePreviewContainer}>
          <Avatar
            className={classes.imagePreview}
            src={props.image_url}
            alt="preview"
          />

          <TextField
            value={props.image_url}
            onChange={event => {
              props.onValueChange({ image_url: event.target.value });
            }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="image_url"
            label="Image Url"
            id="image_url"
            autoComplete="image_url"
          />
        </div>
        <TextField
          value={props.name}
          onChange={event => {
            props.onValueChange({ name: event.target.value });
          }}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoComplete="name"
          autoFocus
        />
        <TextField
          value={props.description}
          onChange={event => {
            props.onValueChange({ description: event.target.value });
          }}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="description"
          label="Description"
          id="description"
          autoComplete="description"
        />
        <TextField
          value={props.url}
          onChange={event => {
            props.onValueChange({ url: event.target.value });
          }}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="url"
          label="Url"
          id="live_url"
          autoComplete="live_url"
        />

        <FormControlLabel
          control={
            <Switch
              checked={props.showInApp}
              onChange={event => {
                props.onValueChange({
                  showInApp: event.target.value
                });
              }}
              value="showInApp"
            />
          }
          label="Show in mobile app"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Submit
        </Button>
      </form>
    </div>
  );
}
