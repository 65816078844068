import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Dashboard from "./Dashboard";
import * as serviceWorker from "./serviceWorker";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

function AlertDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={() => props.handleClose(0)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose(0)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => props.handleClose(1)} color="primary" autoFocus>
          Yes, Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

class MainHandler extends React.Component {
  //-----

  getRequest(url, headers, params, callback) {
    this.request("GET", url, headers, params, callback);
  }

  postRequest(url, headers, params, callback) {
    this.request("POST", url, headers, params, callback);
  }

  request(method, url, headers, params, callback, isJSON) {
    let options = {
      method: method
    };

    let body = "";

    if (!isJSON) {
      Object.keys(params).forEach(key => {
        if (body.length > 0) {
          body = body + "&";
        }

        let value = params[key];
        body = body + key + "=" + value;
      });
    }
    if (method === "POST" && isJSON) {
      headers["Content-Type"] = "application/json";
      options.body = JSON.stringify(params);
    } else if (method === "POST") {
      headers["Content-Type"] = "application/x-www-form-urlencoded";
      options.body = body;
    } else if (body.length > 0) {
      url = url + "?" + body;
    }

    if (headers) {
      options.headers = headers;
    }

    //console.log("[--------");
    //console.log(url);
    //console.log(options);
    //console.log("--------]");

    fetch(url, options)
      .then(response => response.json())
      .then(responseJson => {
        //console.log("|--------");
        //console.log(url);
        //console.log(options);
        //console.log(responseJson);
        //console.log("--------|");
        setTimeout(() => {
          callback(null, responseJson);
        }, 100);
      })
      .catch(error => {
        setTimeout(() => {
          callback(error, null);
        }, 100);
      });
  }

  //-----

  componentDidMount() {
    this.getAllUrlsData();
  }

  verifyLogin = fnc => {
    this.getRequest(
      "https://www.gurpreetmundi.com/apps/index.php/workerapp/login",
      {},
      { email: this.state.email, password: this.state.password },
      (error, response) => {
        //console.log(JSON.stringify(response));
        if (response) {
          if (response.status === "1") {
            fnc(response.details);
          } else {
            alert(response.msg);
          }
        } else {
          alert(error.message);
        }
      }
    );
  };

  addNewUrl = fnc => {
    this.postRequest(
      "https://www.gurpreetmundi.com/apps/index.php/workerapp/addNewLiveUrl",
      {},
      {
        name: this.state.name,
        url: this.state.url,
        image_url: this.state.image_url,
        description: this.state.description,
        status: this.state.showInApp ? 1 : 0
      },
      (error, response) => {
        //console.log(JSON.stringify(response));
        if (response) {
          if (response.status === "1") {
            fnc();
          } else {
            alert(response.msg);
          }
        } else {
          alert(error.message);
        }
      }
    );
  };

  editUrlData = fnc => {
    this.postRequest(
      "https://www.gurpreetmundi.com/apps/index.php/workerapp/editNewLiveUrl",
      {},
      {
        name: this.state.name,
        url: this.state.url,
        image_url: this.state.image_url,
        description: this.state.description,
        status: this.state.showInApp ? 1 : 0,
        id: this.state.editData.id
      },
      (error, response) => {
        //console.log(JSON.stringify(response));
        if (response) {
          if (response.status === "1") {
            fnc();
          } else {
            alert(response.msg);
          }
        } else {
          alert(error.message);
        }
      }
    );
  };

  changeStatusOfUrl = (item, fnc) => {
    this.postRequest(
      "https://www.gurpreetmundi.com/apps/index.php/workerapp/changeStatusOfUrl",
      {},
      {
        status: item.enabled ? 1 : 0,
        id: item.id
      },
      (error, response) => {
        //console.log(JSON.stringify(response));
        if (response) {
          if (response.status === "1") {
            fnc();
          } else {
            alert(response.msg);
          }
        } else {
          alert(error.message);
        }
      }
    );
  };

  deleteUrlData = (_id, fnc) => {
    this.postRequest(
      "https://www.gurpreetmundi.com/apps/index.php/workerapp/deleteUrlData",
      {},
      {
        id: _id
      },
      (error, response) => {
        //console.log(JSON.stringify(response));
        if (response) {
          if (response.status === "1") {
            fnc();
          } else {
            alert(response.msg);
          }
        } else {
          alert(error.message);
        }
      }
    );
  };

  getAllUrlsData = () => {
    this.postRequest(
      "https://www.gurpreetmundi.com/apps/index.php/workerapp/getUrlsData",
      {},
      {
        hkhajkhkh: 1
      },
      (error, response) => {
        //console.log(JSON.stringify(response));
        if (response) {
          if (response.status === "1") {
            response.details.map(item => {
              item.enabled = item.status === "1";
            });
            this.setState({ data: response.details });
          } else {
            alert(response.msg);
          }
        } else {
          alert(error.message);
        }
      }
    );
  };

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      url: "",
      image_url:
        "https://pbs.twimg.com/profile_images/877820504647897089/j7BkYcZ7.jpg",
      description: "",
      showInApp: false,
      loggedInUser: null,
      showAlert: false,
      alertTitle: "",
      alertMessage: "",
      email: "",
      password: "",
      data: []
    };
  }

  addEditSubmitHandler = event => {
    event.preventDefault();

    let { name, url, image_url, description, editData } = this.state;

    if (name.length === 0) {
      alert("Please enter name");
      return;
    }

    if (url.length === 0) {
      alert("Please enter url");
      return;
    }

    if (image_url.length === 0) {
      alert("Please enter Image Url");
      return;
    }

    if (description.length === 0) {
      alert("Please enter description");
      return;
    }

    if (!editData) {
      this.addNewUrl(() => {
        this.setState({
          name: "",
          url: "",
          image_url:
            "https://pbs.twimg.com/profile_images/877820504647897089/j7BkYcZ7.jpg",
          description: "",
          editData: null
        });
        this.getAllUrlsData();
      });
    } else {
      this.editUrlData(() => {
        this.setState({
          name: "",
          url: "",
          image_url:
            "https://pbs.twimg.com/profile_images/877820504647897089/j7BkYcZ7.jpg",
          description: "",
          editData: null
        });
        this.getAllUrlsData();
      });
    }
  };

  loginSubmitHandler = event => {
    event.preventDefault();

    let { email, password } = this.state;

    if (email.length === 0) {
      alert("Please enter email address.");
      return;
    }

    if (password.length === 0) {
      alert("Please enter paswword");
      return;
    }

    this.verifyLogin(details => {
      this.setState({
        loggedInUser: {}
      });
    });
  };

  render() {
    if (this.state.loggedInUser) {
      return (
        <div
          className={{
            height: "100vh",
            width: "100vw"
          }}
        >
          <Dashboard
            data={this.state.data}
            handleToggle={_item => {
              _item.enabled = !_item.enabled;

              this.changeStatusOfUrl(_item, () => {
                this.getAllUrlsData();
              });
            }}
            onDeleteAction={_item => {
              this.setState({
                alertTitle: "Please Confirm your action.",
                alertMessage:
                  "You will not be able to restore this item once deleted. So, please confirm if you want to delete this item?",
                showAlert: true,
                itemToDelete: _item
              });
            }}
            onEditAction={_item => {
              this.setState({
                editData: _item,
                name: _item.name,
                description: _item.description,
                url: _item.url,
                image_url: _item.image,
                showInApp: _item.enabled
              });
            }}
            {...this.state}
            onValueChange={_state => {
              this.setState(_state);
            }}
            handleSubmit={this.addEditSubmitHandler}
          />

          <AlertDialog
            title={this.state.alertTitle}
            message={this.state.alertMessage}
            open={this.state.showAlert}
            handleClose={_i => {
              if (_i === 1) {
                this.deleteUrlData(this.state.itemToDelete.id, () => {
                  let _data = [...this.state.data];
                  let index = _data.indexOf(this.state.itemToDelete);
                  _data.splice(index, 1);
                  this.setState({ data: _data });

                  this.getAllUrlsData();
                });
              }
              this.setState({ showAlert: false, itemToDelete: null });
            }}
          />
        </div>
      );
    }

    return (
      <App
        email={this.state.email}
        passsword={this.state.password}
        rememberMe={this.state.rememberMe}
        onValueChange={_state => {
          this.setState(_state);
        }}
        handleSubmit={e => {
          this.loginSubmitHandler(e);
        }}
      />
    );
  }
}

ReactDOM.render(<MainHandler />, document.getElementById("root"));

serviceWorker.unregister();
