import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Switch from "@material-ui/core/Switch";

import Avatar from "@material-ui/core/Avatar";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxHeight: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper
  },
  imagePreview: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    width: 60,
    height: 60
  }
}));

function SwitchListSecondary(props) {
  const classes = useStyles();

  return (
    <Paper style={{ maxHeight: "95vh", width: "95%", overflow: "auto" }}>
      <List
        subheader={<ListSubheader>All Live Names & Urls</ListSubheader>}
        className={classes.root}
      >
        {props.data.map(_item => {
          return (
            <ListItem key={`list_${_item.id}`}>
              <ListItemIcon>
                <Avatar
                  className={classes.imagePreview}
                  src={_item.image}
                  alt="preview"
                />
              </ListItemIcon>
              <ListItemText
                primary={_item.name}
                secondary={_item.description}
              />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => props.onEditAction(_item)}
                  edge="end"
                  aria-label="Edit"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => props.onDeleteAction(_item)}
                  edge="end"
                  aria-label="Delete"
                >
                  <DeleteIcon />
                </IconButton>
                <Switch
                  edge="end"
                  onChange={() => props.handleToggle(_item)}
                  checked={_item.enabled}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
}

export default SwitchListSecondary;
